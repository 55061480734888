<template>
  <div>
    <Head :title="$t('help.title')"></Head>
    <!-- 背景 -->
    <img src="@/image/bg_03.jpg" class="bg" alt="" :style="'width:'+$setWidth">
    <div class="help" :style="'width:'+$setWidth">
      <div class="help_box" v-for="(item,index) in $t('help.help')" :key="index">
        <div class="help_title common_text">{{item.title}}</div>
        <div class="help_text common_text" style="font-size:calc(var(--text) - 2px)" v-for="(ite,ind) in item.list" :key="ind">{{ite}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      data:{
        nft_price:0
      },
      list:[],
      current_page:1,
      last_page:0
    }
  },
  mounted() {
    this.init();
  },
  created() {
    window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动事件
  },
  destroyed() {
    window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  methods: {
    init() {
      
    },
    next_pages(){
      if(this.list!=undefined&&this.list.length>0){
				this.current_page++
				if (this.last_page<this.current_page) {
					return
				}
				this.init()
			}
    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("滚动到底部了")
        this.next_pages()
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .bg{
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
  }
  .help{
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    .help_box{
      background-color: rgba(#1A328B,0.5);
      color: #ffffff;
      border-radius: 10px;
      padding: 0 10px;
      margin-top: 10px;
      .help_title{
        padding: 10px 0;
        margin-bottom: 5px;
        border-bottom: 1px solid #1A328B;
      }
      .help_text{
        padding: 3px 0;
      }
    }
  }
</style>